import { Box } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { get } from "lodash";
import { useRecordContext } from "react-admin";
import type { DatagridOrFilterRequiredProps } from "../../../layout/types";
import type { EndUser } from "../type";

export function NPSField({ source }: DatagridOrFilterRequiredProps) {
  const record = useRecordContext<EndUser>();
  const value = get(record, source ?? "nps");

  const color =
    value === undefined
      ? grey
      : value > 8
      ? green
      : value > 6
      ? grey
      : value <= 6
      ? red
      : grey;

  return (
    <Box
      sx={{
        backgroundColor: color["50"],
        color: color["400"],
        borderRadius: 1,
        textAlign: "center",
        fontWeight: "bold",
        paddingX: 1,
        paddingY: "0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "min-content",
        whiteSpace: "nowrap",
        marginY: 0.2,
      }}
    >
      {value ?? "-"}
    </Box>
  );
}
