import { Badge } from "@mui/material";
import type { ReactNode } from "react";
import { useFieldValue } from "react-admin";
import { useGetCount } from "../providers/data-hooks/useGetCount";

type CountRefenceBadgeFieldProps = {
  reference: string;
  source: string;
  children: ReactNode;
};

export function CountRefenceBadgeField({
  reference,
  source,
  children,
}: CountRefenceBadgeFieldProps) {
  const id = useFieldValue({ source: "id" });
  const count = useGetCount(reference, { [source]: id }, { enabled: !!id });

  return (
    <Badge badgeContent={count} color="primary" showZero>
      {children}
    </Badge>
  );
}
