import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import {
  DeleteButton,
  EditButton,
  useListContext,
  useRecordContext,
} from "react-admin";
import { EndUserEditionForm } from "../../end-users/Edit";

export function EndUserActions() {
  const { data } = useListContext();
  const record = useRecordContext();

  if (!record || !data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <CreateInDialogButton
        record={{ housingIds: [record.id] }}
        title="Create a user"
        maxWidth="lg"
        ButtonProps={{
          sx: { marginLeft: 1 },
          variant: "outlined",
        }}
      >
        <EndUserEditionForm />
      </CreateInDialogButton>
    );
  } else {
    return (
      <>
        <EditButton
          record={data[0]}
          resource="end-users"
          variant="outlined"
          sx={{ marginLeft: 1 }}
        />
        <DeleteButton
          mutationMode="pessimistic"
          redirect={false}
          record={data[0]}
          variant="outlined"
          sx={{ marginLeft: 1 }}
        />
      </>
    );
  }
}
