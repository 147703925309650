import { Datagrid, Pagination, ReferenceManyField } from "react-admin";
import { communicationFields } from "../../../../communications/fields";

export const ProjectCommunications = () => {
  return (
    <ReferenceManyField
      reference="communications"
      target="context.projectId"
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        {communicationFields.date}
        {communicationFields.topic}
        {communicationFields.medium}
        {communicationFields.status}
        {communicationFields.resident}
      </Datagrid>
    </ReferenceManyField>
  );
};
